import React, { useState } from 'react';
import './relics-stats.css';
import relics from './data/relics.json';

const orderedRelics = Object
    .values(relics)
    // .filter(relic => {
    //     return relic.id != 'GrassCuttingFire' && relic.id != 'GrassCuttingAir' && relic.id != 'GrassCuttingLightning'
    // })
    .sort((r1, r2) => r1.name.localeCompare(r2.name));

function abilityValueToString(values) {
    if (Array.isArray(values)) {
        return values.join(' / ');
    } else {
        return values.toString();
    }
}

function camelCaseToSeparated(input) {
    return input
        .replace(/([a-z])([A-Z])/g, '$1 $2') // Add space between lowercase and uppercase
        .replace(/([A-Z])([A-Z][a-z])/g, '$1 $2') // Handle cases like "HTMLParser"
        .toLowerCase(); // Optionally, convert to lowercase
}

const abilityMappings = {
    "AbilityActionDefinitionNoTarget": "No Target",
    "AbilityActionDefinitionUnitTarget": "Unit Target",
    "AbilityActionDefinitionPointTarget": "Point Target",
    "manaCost": "Mana Cost",
    "maxHealthCost": "Max Health Cost",
    "cooldown": "Cooldown",
    "castRange": "Cast Range",
    "primaryRadius": "Primary Radius",
};

const tierMappings = {
    0: '1',
    1: '2',
    2: '3',
};

const RelicsStats = () => {
    const [relicList, setRelicList] = useState(orderedRelics);

    const [tierFilters, setTierFilters] = useState([
        { name: 'Show All', type: null, selected: true },
        { name: 'Tier 1', tier: 0, selected: false },
        { name: 'Tier 2', tier: 1, selected: false },
        { name: 'Tier 3', tier: 2, selected: false },
    ]);
    
    const [targetFilters, setTargetFilters] = useState([
        { name: 'Show All', type: null, selected: true },
        { name: 'Passive', type: '', selected: false },
        { name: 'Unit Target', type: 'AbilityActionDefinitionUnitTarget', selected: false },
        { name: 'Point Target', type: 'AbilityActionDefinitionPointTarget', selected: false },
        { name: 'No Target', type: 'AbilityActionDefinitionNoTarget', selected: false },
    ]);

    const getSelectedFilters = (filters) => {
        return filters.find(filter => filter.selected);
    };

    const filterRelics = (tierFilter, targetFilter) => {
        return orderedRelics
            .filter((relic) => {
                const tierResult = 
                    (tierFilter.tier == null) ||
                    (tierFilter.tier == relic.tier);

                const targetResult =
                    (targetFilter.type == null) ||
                    (targetFilter.type == '' && relic.action == null) ||
                    (relic.action && relic.action.tag == targetFilter.type);

                return tierResult && targetResult;
            })
    };

    const handleTierFilterClick = (index) => {
        const newFilters = tierFilters.map((filter, i) => ({
            ...filter,
            selected: i === index,
        }));
        setTierFilters(newFilters);
        const selectedTierFilters = getSelectedFilters(newFilters);
        const selectedTargetFilters = getSelectedFilters(targetFilters);
        setRelicList(filterRelics(selectedTierFilters, selectedTargetFilters));
    };

    const handleTargetFilterClick = (index) => {
        const newFilters = targetFilters.map((filter, i) => ({
            ...filter,
            selected: i === index,
        }));
        setTargetFilters(newFilters);
        const selectedTierFilters = getSelectedFilters(tierFilters);
        const selectedTargetFilters = getSelectedFilters(newFilters);
        setRelicList(filterRelics(selectedTierFilters, selectedTargetFilters));
    };

    return (
        <div className="relics-page">
            <h1>Relics</h1>
            <section className="relics-filter">
                <div className="relics-filter-group">
                    <span className="relics-filter-group-title">Tier:</span>
                    <ul>
                        {tierFilters.map((filter, index) => (
                            <li key={index} onClick={() => handleTierFilterClick(index)} className={filter.selected ? 'selected' : ''}>{filter.name}</li>
                        ))}
                    </ul>
                </div>
                <div className="relics-filter-group">
                    <span className="relics-filter-group-title">Target:</span>
                    <ul>
                        {targetFilters.map((filter, index) => (
                            <li key={index} onClick={() => handleTargetFilterClick(index)} className={filter.selected ? 'selected' : ''}>{filter.name}</li>
                        ))}
                    </ul>
                </div>
            </section>
            <ul className="relic-list">
            {relicList.map((relic) => {
                var icon = '';
                try {
                    icon = require('./icons/relics/' + relic.icon);
                } catch (error) { }

                var values = { };
                if (relic.action && relic.action.val) {
                    values = relic.action.val;
                }

                return (
                    <li key={ relic.idNumeric }>
                    <article className="relic">
                        <header className="relic-header">
                            <img className="relic-icon" src={ icon } alt={ relic.name } />
                            <h3 className="relic-title">{ relic.name }</h3>
                        </header>
                        <main className="relic-main">
                            <section className="relic-ability">
                                <ul>
                                    <li>
                                        <div className="key">Tier</div>
                                        <div className="value">{ tierMappings[relic.tier] ?? '?' }</div>
                                    </li>
                                    <li key="type">
                                        <div className="key">Type</div>
                                        <div className="value">{ relic.action ? (abilityMappings[relic.action.tag] ?? '') : 'Passive' }</div>
                                    </li>
                                </ul>
                            </section>
                            <section className="relic-description">
                                <p dangerouslySetInnerHTML={{ __html: relic.description.replace(/\r?\n/g, '<br>') }}/>
                            </section>
                            <section className="relic-values">
                                <ul>
                                    {Object.entries(values).map(([key, value]) => (
                                        <li key={key}>
                                            <div className="key">{abilityMappings[key]}</div>
                                            <div className="value" dangerouslySetInnerHTML={{ __html: abilityValueToString(value) }}></div>
                                        </li>
                                    ))}
                                    {relic.values.map(value => (
                                        <li>
                                            <div className="key">{camelCaseToSeparated(value.name)}</div>
                                            <div className="value" dangerouslySetInnerHTML={{ __html: abilityValueToString(value.value) }}></div>
                                        </li>
                                    ))}
                                </ul>
                            </section>
                            <section className="relic-upgrades">
                                {relic.upgrades.map((upgrade) => {
                                    return (
                                        <div key={ upgrade.name } className="relic-upgrade">
                                            <div className="relic-upgrade-type">{ upgrade.type }</div>
                                            <div className="relic-upgrade-description" dangerouslySetInnerHTML={{ __html: upgrade.description.replace(/\r?\n/g, '<br>') }}/>
                                        </div>
                                    )
                                })}
                            </section>
                        </main>
                    </article>
                    </li>
                )
            })}
            </ul>
        </div>
    );
};

export default RelicsStats;
